import axios from "axios";
import React, { useCallback, useState, useRef, useMemo } from "react";
import { getTranslatedCountryList } from "../../utils/intlUtil";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

// Components
import ReCaptcha from "../../common/components/ReCaptcha";

import Member_img from "../../assets/img/contact.jpg";

const messages = defineMessages({
  breadcrumb: {
    id: "page.members.breadcrumb",
    description: "page members breadcrumb",
    defaultMessage: "Miembros",
  },
  title: {
    id: "page.members.pageTitle",
    description: "page members title",
    defaultMessage: "Házte miembro",
  },
  intro: {
    id: "page.members.pageIntro",
    description: "page members intro",
    defaultMessage:
      "Contacta con nosotros si quieres ser uno de los #FlowyHotels.",
  },
  formDefault: {
    id: "page.members.formDefault",
    description: "members form default",
    defaultMessage: "Seleccionar",
  },
  formName: {
    id: "page.members.formName",
    description: "members form name",
    defaultMessage: "Nombre",
  },
  formCompany: {
    id: "page.members.formCompany",
    description: "members form company",
    defaultMessage: "Empresa",
  },
  formPhone: {
    id: "page.members.formPhone",
    description: "members form phone",
    defaultMessage: "Teléfono",
  },
  formEmail: {
    id: "page.members.formEmail",
    description: "members form email",
    defaultMessage: "Email",
  },
  formWeb: {
    id: "page.members.formWeb",
    description: "members form web",
    defaultMessage: "Web",
  },
  formCountry: {
    id: "page.members.formCountry",
    description: "members form copuntry",
    defaultMessage: "País",
  },
  formMessage: {
    id: "page.members.formMessage",
    description: "members form message",
    defaultMessage: "Mensaje",
  },
  formMust: {
    id: "page.members.formMust",
    description: "members form must",
    defaultMessage: "* Campos obligatorios",
  },
  formConditions: {
    id: "page.members.formConditions",
    description: "members form conditions",
    defaultMessage: "Sí, acepto las",
  },
  formConditionsLink: {
    id: "page.members.formConditionsLink",
    description: "members form conditions link",
    defaultMessage: "condiciones de privacidad",
  },
  formNews: {
    id: "page.members.formNews",
    description: "members form news",
    defaultMessage: "Sí, deseo recibir novedades",
  },
  formBtn: {
    id: "page.members.formBtn",
    description: "members form button",
    defaultMessage: "Enviar",
  },
  formSuccess: {
    id: "page.members.formSuccess",
    description: "members form success",
    defaultMessage: "Se ha enviado el mensaje correctamente.",
  },
  formError: {
    id: "page.members.formError",
    description: "members form error",
    defaultMessage: "Ha ocurrido un error.",
  },
  select: {
    id: "page.members.select",
    description: "select",
    defaultMessage: "Seleccionar",
  },
});

export default function Members({ locale }) {
  const [form, setForm] = useState({});
  const [showMessageError, setShowMessageError] = useState(false);
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [showFieldMissing, setShowFieldMissing] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const countries = useMemo(() => getTranslatedCountryList(locale), [locale]);

  const hideMessages = useCallback(() => {
    if (showMessageError) {
      setShowMessageError(false);
    }
    if (showMessageSuccess) {
      setShowMessageSuccess(false);
    }
  }, [showMessageError, showMessageSuccess]);

  const onChange = useCallback(
    (event) => {
      hideMessages();
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      const name = event.target.name;
      setForm((prevState) => ({ ...prevState, [name]: value }));
    },
    [hideMessages]
  );

  const sendContactForm = useCallback(
    async (e) => {
      e.preventDefault();
      const isValidForm = formRef.current && formRef.current.checkValidity();
      if (isValidForm && captchaToken) {
        const country = countries.find((c) => c.value === form.country) || {};
        const data = {
          form: "MEMBER_FLOWY",
          name: form.name,
          company: form.company,
          phone: form.phone,
          email: form.email,
          web: form.web,
          country: country.label,
          message: form.message || "",
          receiveNews: form.receiveNews || false,
          captchaToken,
        };
        try {
          await axios.post("/api", JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
            },
          });
          formRef.current && formRef.current.reset();
          setForm({});
          setShowMessageSuccess(true);
        } catch (error) {
          console.log(error);
          setShowMessageError(true);
        }
        setShowFieldMissing(false);
      } else {
        setShowFieldMissing(true);
      }
    },
    [form, formRef, captchaToken, countries]
  );

  const handleSuccessCaptcha = useCallback(
    (token) => {
      setCaptchaToken(token);
    },
    [setCaptchaToken]
  );

  return (
    <>
      <section className="contact-page half-wrapper responsive-multimedia-off">
        <div className="half-breadcrumbs color">
          <ol
            className="breadcrumbs-list"
            itemScope
            itemType="http://schema.org/BreadcrumbList"
          >
            <li
              className="breadcrumbs-list-element"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <span itemProp="position" content="1">
                &gt;
              </span>
            </li>
          </ol>
        </div>
        <div className="wrapper">
          <div className="wrap-contact-page">
            <header className="contact-page-header">
              <div className="wrap-contact-header">
                <h1 className="contact-header-title">
                  <FormattedMessage {...messages.title} />
                </h1>
              </div>
            </header>
            <div className="contact-page-info">
              <div className="wrap-contact-info">
                <p className="contact-info-text">
                  <FormattedMessage {...messages.intro} />
                </p>
              </div>
            </div>
            <form
              ref={formRef}
              className={`${showFieldMissing && "show-error-form"}`}
            >
              <div className="contact-page-form">
                <div className="wrap-contact-form">
                  <div className="contact-form-fields">
                    <div className="wrap-contact-fields">
                      <div className="members-fields-element full"></div>
                      <div className="members-fields-element">
                        <div className="input-element user must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.name &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formName} />
                            </span>
                            <input
                              className="input-element-field "
                              type="text"
                              placeholder="..."
                              name="name"
                              onChange={onChange}
                              value={form.name}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element">
                        <div className="input-element company must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.company &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formCompany} />
                            </span>
                            <input
                              className="input-element-field"
                              type="text"
                              placeholder="..."
                              name="company"
                              onChange={onChange}
                              value={form.company}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element">
                        <div className="input-element phone must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.phone &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formPhone} />
                            </span>
                            <input
                              className="input-element-field"
                              type="tel"
                              placeholder="..."
                              name="phone"
                              onChange={onChange}
                              value={form.phone}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element">
                        <div className="input-element email must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.email &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formEmail} />
                            </span>
                            <input
                              className="input-element-field"
                              type="email"
                              placeholder="@..."
                              name="email"
                              onChange={onChange}
                              value={form.email}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element">
                        <div className="input-element screen must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.web &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formWeb} />
                            </span>
                            <input
                              className="input-element-field"
                              type="text"
                              placeholder="https://www..."
                              name="web"
                              onChange={onChange}
                              value={form.web}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element">
                        <div className="input-element select must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.country &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formCountry} />
                            </span>
                            <select
                              className="input-element-field"
                              name="country"
                              onChange={onChange}
                              value={form.country}
                              required
                            >
                              <option label="Seleccionar" value=""></option>
                              {countries.map((country) => (
                                <option value={country.value}>
                                  {country.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element full">
                        <div className="input-element textarea">
                          <div className="wrap-input-element">
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formMessage} />
                            </span>
                            <textarea
                              className="input-element-field"
                              placeholder="..."
                              name="message"
                              onChange={onChange}
                              value={form.message}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="members-fields-element">
                        <ReCaptcha onSuccess={handleSuccessCaptcha} />
                      </div>
                    </div>
                  </div>
                  <div className="contact-form-foot">
                    <div className="wrap-form-foot">
                      <div className="form-foot-checks">
                        <div className="wrap-foot-checks">
                          <div className="must-element">
                            <p className="must-element-text">
                              <FormattedMessage {...messages.formMust} />
                            </p>
                          </div>
                          <label className="checkbox-element">
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              name="conditions"
                              value={form.conditions}
                              onChange={onChange}
                              required
                            />
                            <div
                              className={`checkbox-icon ${
                                showFieldMissing &&
                                !form.conditions &&
                                "checkbox-icon-invalid"
                              }`}
                            ></div>
                            <p className="checkbox-text">
                              <FormattedMessage {...messages.formConditions} />{" "}
                              <a
                                href="/policy"
                                target="_blank"
                                className="checkbox-text-link"
                              >
                                <FormattedMessage
                                  {...messages.formConditionsLink}
                                />
                              </a>
                            </p>
                          </label>
                          <label className="checkbox-element">
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              name="receiveNews"
                              value={form.receiveNews}
                              onChange={onChange}
                            />
                            <div className="checkbox-icon"></div>
                            <p className="checkbox-text">
                              <FormattedMessage {...messages.formNews} />
                            </p>
                          </label>
                        </div>
                      </div>
                      <div className="form-foot-btns">
                        <div className="wrap-foot-btns">
                          <div>
                            {showMessageSuccess && (
                              <p className="form-message form-message-success">
                                <FormattedMessage {...messages.formSuccess} />
                              </p>
                            )}
                            {showMessageError && (
                              <p className="form-message form-message-error">
                                <FormattedMessage {...messages.formError} />
                              </p>
                            )}
                          </div>
                          <button
                            type="button"
                            onClick={sendContactForm}
                            className="btn-corp btn-small"
                          >
                            <p className="btn-corp-text">
                              <FormattedMessage {...messages.formBtn} />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="half-multimedia">
              <div className="wrap-half-multimedia">
                <div className="half-multimedia-box">
                  <div className="wrap-multimedia-box">
                    <figure
                      className="multimedia-box-element"
                      role="img"
                      data-lazyimg={Member_img}
                    ></figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
