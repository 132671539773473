import React from "react";

// Assets
import "../styles/textComponent.scss";

// Components

export default function textComponent({
  titleText,
  subtitleText,
  paragraphText,
  buttonText,
  buttonText2,
  buttonInfo,
}) {
  return (
    <div>
      <h1>{titleText}</h1>
      <h2 className="subtitleText">{subtitleText}</h2>
      <p className="textComponentP">{paragraphText}</p>
      {buttonInfo === "follow" ? (
        <div className="banner-btns-element btn-corp">
          <a
            href="https://www.instagram.com/flowyhotels/"
            className="btn-corp-text"
          >
            {buttonText}
          </a>
        </div>
      ) : (
        <div className="banner-btns-element btn-corp">
          <a href="/miembros" className="btn-corp-text">
            {buttonText}
          </a>
        </div>
      )}

      {buttonText2 && (
        <div className="banner-btns-element btn-corp ">
          <a className="btn-corp-text" href="/#deals">
            {buttonText2}{" "}
          </a>
        </div>
      )}
    </div>
  );
}
