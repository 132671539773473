import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import React from "react";
import taggedImg from "../../assets/img/taggedImg.jpeg";
import taggedImg2 from "../../assets/img/taggedImg2.jpg";
import taggedImg3 from "../../assets/img/taggedImg3.jpeg";
import taggedImg4 from "../../assets/img/taggedImg4.jpeg";
import taggedImg5 from "../../assets/img/taggedImg5.jpeg";
import phoneImg from "../../assets/img/phone.png";
import hotelImg from "../../assets/img/mas_salagros.jpg";

import TaggedImg from "../../layout/taggedImg";
import InlinksBanner from "../../layout/inlinksBanner";
import ImgTextBanner from "../../layout/imgTextBanner";

export default function Home() {
  const messages = defineMessages({
    //imgTextBanner
    titleText: {
      id: "page.imgTextBanner.titleText",
      description: "banner title",
      defaultMessage: "Descubre nuestra colección de hoteles singulares",
    },
    titleText2: {
      id: "page.imgTextBanner.titleText2",
      description: "banner title",
      defaultMessage: "¿Tu hotel va contracorriente?",
    },
    subtitleText: {
      id: "page.imgTextBanner.subtitleText",
      description: "banner subtitle",
      defaultMessage: "LA MEJOR INSPIRACIÓN Y DEALS",
    },
    subtitleText2: {
      id: "page.imgTextBanner.subtitleText2",
      description: "button subtitle",
      defaultMessage: "BE FLOWY",
    },
    paragraphText: {
      id: "page.imgTextBanner.paragraphText",
      description: "banner text",
      defaultMessage:
        "Visita nuestra cuenta de Instagram y descubre las mejores ideas para tu próxima escapada",
    },
    paragraphText2: {
      id: "page.imgTextBanner.paragraphText2",
      description: "banner text",
      defaultMessage:
        "Únete a la comunidad de hoteles singulares con más flow, sin cuotas ni comisiones.",
    },
    buttonText: {
      id: "page.imgTextBanner.buttonText",
      description: "banner button text",
      defaultMessage: "Síguenos",
    },
    buttonText2: {
      id: "page.imgTextBanner.buttonText2",
      description: "banner button text 2",
      defaultMessage: "Recibe nuestros deals",
    },
    buttonText3: {
      id: "page.imgTextBanner.buttonText3",
      description: "banner button text",
      defaultMessage: "Hazte miembro",
    },
    //taggedImage
    paragraph1: {
      id: "page.taggedImage.paragraph1",
      description: "paragraph1 text",
      defaultMessage: "HOTELES CON FLOW",
    },
    paragraph2: {
      id: "page.taggedImage.paragraph2",
      description: "paragraph2 text",
      defaultMessage: "PARA LOS QUE VAN A CONTRACORRIENTE",
    },
    paragraph3: {
      id: "page.taggedImage.paragraph3",
      description: "paragraph3 text",
      defaultMessage: "INSPIRACIÓN Y DEALS EXCLUSIVOS",
    },
    paragraph4: {
      id: "page.taggedImage.paragraph4",
      description: "paragraph4 text",
      defaultMessage: "PARA TUS ESCAPADAS",
    },
    hotelName: {
      id: "page.taggedImage.hotelName",
      description: "hotel name text",
      defaultMessage: "Hotel Can Ferrereta",
    },
    hotelLocation: {
      id: "page.taggedImage.hotelLocation",
      description: "hotel location text",
      defaultMessage: "Santanyí, Mallorca",
    },
    //taggedImage2
    paragraph1: {
      id: "page.taggedImage2.paragraph1",
      description: "paragraph1 text",
      defaultMessage: "INVIERTE EN LO QUE",
    },
    paragraph2: {
      id: "page.taggedImage2.paragraph2",
      description: "paragraph2 text",
      defaultMessage: "TE HACE REALMENTE FELIZ",
    },
    paragraph3: {
      id: "page.taggedImage2.paragraph3",
      description: "paragraph3 text",
      defaultMessage: "VIAJAR ES COSA DE LISTOS",
    },
    hotelName: {
      id: "page.taggedImage2.hotelName",
      description: "hotel name text",
      defaultMessage: "Villa Favorita",
    },
    hotelLocation: {
      id: "page.taggedImage2.hotelLocation",
      description: "hotel location text",
      defaultMessage: "San Sebastián",
    },
    //taggedImage3
    paragraph1: {
      id: "page.taggedImage3.paragraph1",
      description: "paragraph1 text",
      defaultMessage: "EL LUJO YA NO SE MIDE",
    },
    paragraph2: {
      id: "page.taggedImage3.paragraph2",
      description: "paragraph2 text",
      defaultMessage: "EN ESTRELLAS,",
    },
    paragraph3: {
      id: "page.taggedImage3.paragraph3",
      description: "paragraph3 text",
      defaultMessage: "SE MIDE EN EXPERIENCIAS",
    },
    hotelName: {
      id: "page.taggedImage3.hotelName",
      description: "hotel name text",
      defaultMessage: "Fontsanta Hotel Thermal Spa & Wellness",
    },
    hotelLocation: {
      id: "page.taggedImage3.hotelLocation",
      description: "hotel location text",
      defaultMessage: "Campos, Mallorca",
    },
    //taggedImage4
    paragraph1: {
      id: "page.taggedImage4.paragraph1",
      description: "paragraph1 text",
      defaultMessage: "LOS CAPRICHOS DEBERÍAN",
    },
    paragraph2: {
      id: "page.taggedImage4.paragraph2",
      description: "paragraph2 text",
      defaultMessage: "SER URGENCIAS",
    },
    paragraph3: {
      id: "page.taggedImage4.paragraph3",
      description: "paragraph3 text",
      defaultMessage: "PONLE SAL A LA VIDA!",
    },
    hotelName: {
      id: "page.taggedImage4.hotelName",
      description: "hotel name text",
      defaultMessage: "Icon Malabar",
    },
    hotelLocation: {
      id: "page.taggedImage4.hotelLocation",
      description: "hotel location text",
      defaultMessage: "Málaga",
    },
    //taggedImage5
    paragraph1: {
      id: "page.taggedImage5.paragraph1",
      description: "paragraph1 text",
      defaultMessage: "PARA LOS AMANTES",
    },
    paragraph2: {
      id: "page.taggedImage5.paragraph2",
      description: "paragraph2 text",
      defaultMessage: "DE LA HOTELERÍA",
    },
    paragraph3: {
      id: "page.taggedImage5.paragraph3",
      description: "paragraph3 text",
      defaultMessage: "MÁS AUTÉNTICA Y PERSONAL",
    },
    hotelName: {
      id: "page.taggedImage5.hotelName",
      description: "hotel name text",
      defaultMessage: "Pleta de Mar",
    },
    hotelLocation: {
      id: "page.taggedImage5.hotelLocation",
      description: "hotel location text",
      defaultMessage: "Capdepera, Mallorca",
    },
  });

  const Intl = useIntl();

  const taggedImgInfo1 = [
    {
      image: taggedImg,
      paragraph1: Intl.formatMessage({ id: "page.taggedImage.paragraph1" }),
      paragraph2: Intl.formatMessage({ id: "page.taggedImage.paragraph2" }),
      paragraph3: Intl.formatMessage({ id: "page.taggedImage.paragraph3" }),
      paragraph4: Intl.formatMessage({ id: "page.taggedImage.paragraph4" }),
      hotelName: Intl.formatMessage({ id: "page.taggedImage.hotelName" }),
      hotelLocation: Intl.formatMessage({
        id: "page.taggedImage.hotelLocation",
      }),
    },
  ];
  const taggedImgInfo2 = [
    {
      image: taggedImg2,
      paragraph1: Intl.formatMessage({ id: "page.taggedImage2.paragraph1" }),
      paragraph2: Intl.formatMessage({ id: "page.taggedImage2.paragraph2" }),
      paragraph3: Intl.formatMessage({ id: "page.taggedImage2.paragraph3" }),
      hotelName: Intl.formatMessage({ id: "page.taggedImage2.hotelName" }),
      hotelLocation: Intl.formatMessage({
        id: "page.taggedImage2.hotelLocation",
      }),
    },
  ];
  const taggedImgInfo3 = [
    {
      image: taggedImg3,
      paragraph1: Intl.formatMessage({ id: "page.taggedImage3.paragraph1" }),
      paragraph2: Intl.formatMessage({ id: "page.taggedImage3.paragraph2" }),
      paragraph3: Intl.formatMessage({ id: "page.taggedImage3.paragraph3" }),
      hotelName: Intl.formatMessage({ id: "page.taggedImage3.hotelName" }),
      hotelLocation: Intl.formatMessage({
        id: "page.taggedImage3.hotelLocation",
      }),
    },
  ];
  const taggedImgInfo4 = [
    {
      image: taggedImg4,
      paragraph1: Intl.formatMessage({ id: "page.taggedImage4.paragraph1" }),
      paragraph2: Intl.formatMessage({ id: "page.taggedImage4.paragraph2" }),
      paragraph3: Intl.formatMessage({ id: "page.taggedImage4.paragraph3" }),
      hotelName: Intl.formatMessage({ id: "page.taggedImage4.hotelName" }),
      hotelLocation: Intl.formatMessage({
        id: "page.taggedImage4.hotelLocation",
      }),
    },
  ];
  const taggedImgInfo5 = [
    {
      image: taggedImg5,
      paragraph1: Intl.formatMessage({ id: "page.taggedImage5.paragraph1" }),
      paragraph2: Intl.formatMessage({ id: "page.taggedImage5.paragraph2" }),
      paragraph3: Intl.formatMessage({ id: "page.taggedImage5.paragraph3" }),
      hotelName: Intl.formatMessage({ id: "page.taggedImage5.hotelName" }),
      hotelLocation: Intl.formatMessage({
        id: "page.taggedImage5.hotelLocation",
      }),
    },
  ];

  return (
    <div>
      <TaggedImg Info={taggedImgInfo1} />
      <ImgTextBanner
        imgSrc={phoneImg}
        titleText={<FormattedMessage {...messages.titleText} />}
        subtitleText={<FormattedMessage {...messages.subtitleText} />}
        paragraphText={<FormattedMessage {...messages.paragraphText} />}
        buttonText={<FormattedMessage {...messages.buttonText} />}
        buttonText2={<FormattedMessage {...messages.buttonText2} />}
        imagePosition={true}
        buttonInfo={"follow"}
      />
      <TaggedImg Info={taggedImgInfo2} />
      <InlinksBanner bannerClass="Instagram" />
      <TaggedImg Info={taggedImgInfo3} />
      <ImgTextBanner
        imgSrc={hotelImg}
        titleText={<FormattedMessage {...messages.titleText2} />}
        subtitleText={<FormattedMessage {...messages.subtitleText2} />}
        paragraphText={<FormattedMessage {...messages.paragraphText2} />}
        buttonText={<FormattedMessage {...messages.buttonText3} />}
        imagePosition={false}
        buttonInfo={"member"}
      />
      <TaggedImg Info={taggedImgInfo4} />
      <InlinksBanner bannerClass="Subscribe" />
      <TaggedImg Info={taggedImgInfo5} />
      <InlinksBanner bannerClass="Contact" />
    </div>
  );
}
