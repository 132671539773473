import React, { useMemo } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import BreadCrumbs from "../../layout/breadcrumbs";

const messages = defineMessages({
  breadcrumb: {
    id: "page.pricing.breadcrumb",
    description: "page pricing breadcrumb",
    defaultMessage: "Precios",
  },
  title: {
    id: "page.pricing.pageTitle",
    description: "page pricing title",
    defaultMessage: "Precios",
  },
  intro: {
    id: "page.pricing.pageIntro",
    description: "page pricing intro",
    defaultMessage: "Coste fijo o variable, tú eliges",
  },
  formTitle: {
    id: "page.pricing.formTitle",
    description: "pricing form title",
    defaultMessage: "Indica tu tamaño",
  },
  formSubtitle: {
    id: "page.pricing.formSubtitle",
    description: "pricing form subtitle",
    defaultMessage: "Selecciona cuántas habitaciones tienes",
  },
  form20: {
    id: "page.pricing.form20",
    description: "pricing form option 20",
    defaultMessage: "1-20 habitaciones",
  },
  form50: {
    id: "page.pricing.form50",
    description: "pricing form option 50",
    defaultMessage: "21-50 habitaciones",
  },
  form100: {
    id: "page.pricing.form100",
    description: "pricing form option 100",
    defaultMessage: "51-100 habitaciones",
  },
  form200: {
    id: "page.pricing.form200",
    description: "pricing form option 200",
    defaultMessage: "101-200 habitaciones",
  },
  form200plus: {
    id: "page.pricing.form200plus",
    description: "pricing form option 200 plus",
    defaultMessage: "+200 habitaciones",
  },
  ratesTitle: {
    id: "page.pricing.ratesTitle",
    description: "pricing rates title",
    defaultMessage: "Elige la tarifa que más te convenga",
  },
  ratesBtn: {
    id: "page.pricing.ratesBtn",
    description: "pricing rates button",
    defaultMessage: "Contratar tarifa",
  },
  rates2: {
    id: "page.pricing.rates2",
    description: "pricing rates 2",
    defaultMessage: "Tarifa 2%",
  },
  rates2Text: {
    id: "page.pricing.rates2Text",
    description: "pricing rates 2 text",
    defaultMessage: "Sin coste fijo",
  },
  rates1: {
    id: "page.pricing.rates1",
    description: "pricing rates 1",
    defaultMessage: "Tarifa 1%",
  },
  rates1Text: {
    id: "page.pricing.rates1Text",
    description: "pricing rates 1 text",
    defaultMessage: "Minimiza tu riesgo",
  },
  rates05: {
    id: "page.pricing.rates05",
    description: "pricing rates 05",
    defaultMessage: "Tarifa 0,5%",
  },
  rates05Text: {
    id: "page.pricing.rates05Text",
    description: "pricing rates 05 text",
    defaultMessage: "Equilibra tus costes",
  },
  rates0: {
    id: "page.pricing.rates0",
    description: "pricing rates 0",
    defaultMessage: "Tarifa 0%",
  },
  rates0Text: {
    id: "page.pricing.rates0Text",
    description: "pricing rates 0 text",
    defaultMessage: "Sin coste variable",
  },
  option20Rates2Percentage: {
    id: "page.pricing.option20Rates2Percentage",
    description: "pricing 20 Rate 2% Percentage",
    defaultMessage: "2%",
  },
  option20Rates2PercentageText: {
    id: "page.pricing.option20Rates2PercentageText",
    description: "pricing 20 Rate 2% Percentage text",
    defaultMessage: "de las reservas",
  },
  option20Rates2Terms: {
    id: "page.pricing.option20Rates2Terms",
    description: "pricing 20 Rate 2% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option20Rates2Share: {
    id: "page.pricing.option20Rates2Share",
    description: "pricing 20 Rate 2% Share",
    defaultMessage: "Mín. mensual",
  },
  option20Rates2SharePrice: {
    id: "page.pricing.option20Rates2SharePrice",
    description: "pricing 20 Rate 2% Share price",
    defaultMessage: "32€",
  },
  option20Rates1Price: {
    id: "page.pricing.option20Rates1Price",
    description: "pricing 20 Rate 1% PerPricecentage",
    defaultMessage: "64€",
  },
  option20Rates1PriceText: {
    id: "page.pricing.option20Rates1PriceText",
    description: "pricing 20 Rate 1% Price text",
    defaultMessage: "al mes",
  },
  option20Rates1Percentage: {
    id: "page.pricing.option20Rates1Percentage",
    description: "pricing 20 Rate 1% Percentage",
    defaultMessage: "1%",
  },
  option20Rates1PercentageText: {
    id: "page.pricing.option20Rates1PercentageText",
    description: "pricing 20 Rate 1% Percentage text",
    defaultMessage: "de las reservas",
  },
  option20Rates1Terms: {
    id: "page.pricing.option20Rates1Terms",
    description: "pricing 20 Rate 1% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option20Rates05Price: {
    id: "page.pricing.option20Rates05Price",
    description: "pricing 20 Rate 05% PerPricecentage",
    defaultMessage: "97€",
  },
  option20Rates05PriceText: {
    id: "page.pricing.option20Rates05PriceText",
    description: "pricing 20 Rate 05% Price text",
    defaultMessage: "al mes",
  },
  option20Rates05Percentage: {
    id: "page.pricing.option20Rates05Percentage",
    description: "pricing 20 Rate 05% Percentage",
    defaultMessage: "0,5%",
  },
  option20Rates05PercentageText: {
    id: "page.pricing.option20Rates05PercentageText",
    description: "pricing 20 Rate 05% Percentage text",
    defaultMessage: "de las reservas",
  },
  option20Rates05Terms: {
    id: "page.pricing.option20Rates05Terms",
    description: "pricing 20 Rate 05% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option20Rates0Price: {
    id: "page.pricing.option20Rates0Price",
    description: "pricing 20 Rate 0% PerPricecentage",
    defaultMessage: "133€",
  },
  option20Rates0PriceText: {
    id: "page.pricing.option20Rates0PriceText",
    description: "pricing 20 Rate 0% Price text",
    defaultMessage: "al mes",
  },
  option20Rates0Terms: {
    id: "page.pricing.option20Rates0Terms",
    description: "pricing 20 Rate 0% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option20Rates0Share: {
    id: "page.pricing.option20Rates0Share",
    description: "pricing 20 Rate 0% Share",
    defaultMessage: "Hasta 125.000€ anuales",
  },
  option50Rates2Percentage: {
    id: "page.pricing.option50Rates2Percentage",
    description: "pricing 50 Rate 2% Percentage",
    defaultMessage: "2%",
  },
  option50Rates2PercentageText: {
    id: "page.pricing.option50Rates2PercentageText",
    description: "pricing 50 Rate 2% Percentage text",
    defaultMessage: "de las reservas",
  },
  option50Rates2Terms: {
    id: "page.pricing.option50Rates2Terms",
    description: "pricing 50 Rate 2% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option50Rates2Share: {
    id: "page.pricing.option50Rates2Share",
    description: "pricing 50 Rate 2% Share",
    defaultMessage: "Mín. mensual",
  },
  option50Rates2SharePrice: {
    id: "page.pricing.option50Rates2SharePrice",
    description: "pricing 50 Rate 2% Share price",
    defaultMessage: "42€",
  },
  option50Rates1Price: {
    id: "page.pricing.option50Rates1Price",
    description: "pricing 50 Rate 1% PerPricecentage",
    defaultMessage: "84€",
  },
  option50Rates1PriceText: {
    id: "page.pricing.option50Rates1PriceText",
    description: "pricing 50 Rate 1% Price text",
    defaultMessage: "al mes",
  },
  option50Rates1Percentage: {
    id: "page.pricing.option50Rates1Percentage",
    description: "pricing 50 Rate 1% Percentage",
    defaultMessage: "1%",
  },
  option50Rates1PercentageText: {
    id: "page.pricing.option50Rates1PercentageText",
    description: "pricing 50 Rate 1% Percentage text",
    defaultMessage: "de las reservas",
  },
  option50Rates1Terms: {
    id: "page.pricing.option50Rates1Terms",
    description: "pricing 50 Rate 1% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option50Rates05Price: {
    id: "page.pricing.option50Rates05Price",
    description: "pricing 50 Rate 05% PerPricecentage",
    defaultMessage: "134€",
  },
  option50Rates05PriceText: {
    id: "page.pricing.option50Rates05PriceText",
    description: "pricing 50 Rate 05% Price text",
    defaultMessage: "al mes",
  },
  option50Rates05Percentage: {
    id: "page.pricing.option50Rates05Percentage",
    description: "pricing 50 Rate 05% Percentage",
    defaultMessage: "0,5%",
  },
  option50Rates05PercentageText: {
    id: "page.pricing.option50Rates05PercentageText",
    description: "pricing 50 Rate 05% Percentage text",
    defaultMessage: "de las reservas",
  },
  option50Rates05Terms: {
    id: "page.pricing.option50Rates05Terms",
    description: "pricing 50 Rate 05% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option50Rates0Price: {
    id: "page.pricing.option50Rates0Price",
    description: "pricing 50 Rate 0% PerPricecentage",
    defaultMessage: "207€",
  },
  option50Rates0PriceText: {
    id: "page.pricing.option50Rates0PriceText",
    description: "pricing 50 Rate 0% Price text",
    defaultMessage: "al mes",
  },
  option50Rates0Terms: {
    id: "page.pricing.option50Rates0Terms",
    description: "pricing 50 Rate 0% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option50Rates0Share: {
    id: "page.pricing.option50Rates0Share",
    description: "pricing 50 Rate 0% Share",
    defaultMessage: "Hasta 250.000€ anuales",
  },
  option100Rates2Percentage: {
    id: "page.pricing.option100Rates2Percentage",
    description: "pricing 100 Rate 2% Percentage",
    defaultMessage: "2%",
  },
  option100Rates2PercentageText: {
    id: "page.pricing.option100Rates2PercentageText",
    description: "pricing 100 Rate 2% Percentage text",
    defaultMessage: "de las reservas",
  },
  option100Rates2Terms: {
    id: "page.pricing.option100Rates2Terms",
    description: "pricing 100 Rate 2% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option100Rates2Share: {
    id: "page.pricing.option100Rates2Share",
    description: "pricing 100 Rate 2% Share",
    defaultMessage: "Mín. mensual",
  },
  option100Rates2SharePrice: {
    id: "page.pricing.option100Rates2SharePrice",
    description: "pricing 100 Rate 2% Share price",
    defaultMessage: "49€",
  },
  option100Rates1Price: {
    id: "page.pricing.option100Rates1Price",
    description: "pricing 100 Rate 1% PerPricecentage",
    defaultMessage: "98€",
  },
  option100Rates1PriceText: {
    id: "page.pricing.option100Rates1PriceText",
    description: "pricing 100 Rate 1% Price text",
    defaultMessage: "al mes",
  },
  option100Rates1Percentage: {
    id: "page.pricing.option100Rates1Percentage",
    description: "pricing 100 Rate 1% Percentage",
    defaultMessage: "1%",
  },
  option100Rates1PercentageText: {
    id: "page.pricing.option100Rates1PercentageText",
    description: "pricing 100 Rate 1% Percentage text",
    defaultMessage: "de las reservas",
  },
  option100Rates1Terms: {
    id: "page.pricing.option100Rates1Terms",
    description: "pricing 100 Rate 1% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option100Rates05Price: {
    id: "page.pricing.option100Rates05Price",
    description: "pricing 100 Rate 05% PerPricecentage",
    defaultMessage: "162€",
  },
  option100Rates05PriceText: {
    id: "page.pricing.option100Rates05PriceText",
    description: "pricing 100 Rate 05% Price text",
    defaultMessage: "al mes",
  },
  option100Rates05Percentage: {
    id: "page.pricing.option100Rates05Percentage",
    description: "pricing 100 Rate 05% Percentage",
    defaultMessage: "0,5%",
  },
  option100Rates05PercentageText: {
    id: "page.pricing.option100Rates05PercentageText",
    description: "pricing 100 Rate 05% Percentage text",
    defaultMessage: "de las reservas",
  },
  option100Rates05Terms: {
    id: "page.pricing.option100Rates05Terms",
    description: "pricing 100 Rate 05% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option100Rates0Price: {
    id: "page.pricing.option100Rates0Price",
    description: "pricing 100 Rate 0% PerPricecentage",
    defaultMessage: "308€",
  },
  option100Rates0PriceText: {
    id: "page.pricing.option100Rates0PriceText",
    description: "pricing 100 Rate 0% Price text",
    defaultMessage: "al mes",
  },
  option100Rates0Terms: {
    id: "page.pricing.option100Rates0Terms",
    description: "pricing 100 Rate 0% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option100Rates0Share: {
    id: "page.pricing.option100Rates0Share",
    description: "pricing 100 Rate 0% Share",
    defaultMessage: "Hasta 500.000€ anuales",
  },
  option200Rates2Percentage: {
    id: "page.pricing.option200Rates2Percentage",
    description: "pricing 200 Rate 2% Percentage",
    defaultMessage: "2%",
  },
  option200Rates2PercentageText: {
    id: "page.pricing.option200Rates2PercentageText",
    description: "pricing 200 Rate 2% Percentage text",
    defaultMessage: "de las reservas",
  },
  option200Rates2Terms: {
    id: "page.pricing.option200Rates2Terms",
    description: "pricing 200 Rate 2% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200Rates2Share: {
    id: "page.pricing.option200Rates2Share",
    description: "pricing 200 Rate 2% Share",
    defaultMessage: "Mín. mensual",
  },
  option200Rates2SharePrice: {
    id: "page.pricing.option200Rates2SharePrice",
    description: "pricing 200 Rate 2% Share price",
    defaultMessage: "49€",
  },
  option200Rates1Price: {
    id: "page.pricing.option200Rates1Price",
    description: "pricing 200 Rate 1% PerPricecentage",
    defaultMessage: "98€",
  },
  option200Rates1PriceText: {
    id: "page.pricing.option200Rates1PriceText",
    description: "pricing 200 Rate 1% Price text",
    defaultMessage: "al mes",
  },
  option200Rates1Percentage: {
    id: "page.pricing.option200Rates1Percentage",
    description: "pricing 200 Rate 1% Percentage",
    defaultMessage: "1%",
  },
  option200Rates1PercentageText: {
    id: "page.pricing.option200Rates1PercentageText",
    description: "pricing 200 Rate 1% Percentage text",
    defaultMessage: "de las reservas",
  },
  option200Rates1Terms: {
    id: "page.pricing.option200Rates1Terms",
    description: "pricing 200 Rate 1% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200Rates05Price: {
    id: "page.pricing.option200Rates05Price",
    description: "pricing 200 Rate 05% PerPricecentage",
    defaultMessage: "162€",
  },
  option200Rates05PriceText: {
    id: "page.pricing.option200Rates05PriceText",
    description: "pricing 200 Rate 05% Price text",
    defaultMessage: "al mes",
  },
  option200Rates05Percentage: {
    id: "page.pricing.option200Rates05Percentage",
    description: "pricing 200 Rate 05% Percentage",
    defaultMessage: "0,5%",
  },
  option200Rates05PercentageText: {
    id: "page.pricing.option200Rates05PercentageText",
    description: "pricing 200 Rate 05% Percentage text",
    defaultMessage: "de las reservas",
  },
  option200Rates05Terms: {
    id: "page.pricing.option200Rates05Terms",
    description: "pricing 200 Rate 05% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200Rates0Price: {
    id: "page.pricing.option200Rates0Price",
    description: "pricing 200 Rate 0% PerPricecentage",
    defaultMessage: "308€",
  },
  option200Rates0PriceText: {
    id: "page.pricing.option200Rates0PriceText",
    description: "pricing 200 Rate 0% Price text",
    defaultMessage: "al mes",
  },
  option200Rates0Terms: {
    id: "page.pricing.option200Rates0Terms",
    description: "pricing 200 Rate 0% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200Rates0Share: {
    id: "page.pricing.option200Rates0Share",
    description: "pricing 200 Rate 0% Share",
    defaultMessage: "Hasta 500.000€ anuales",
  },
  option200plusRates2Percentage: {
    id: "page.pricing.option200plusRates2Percentage",
    description: "pricing 200 plus Rate 2% Percentage",
    defaultMessage: "2%",
  },
  option200plusRates2PercentageText: {
    id: "page.pricing.option200plusRates2PercentageText",
    description: "pricing 200 plus Rate 2% Percentage text",
    defaultMessage: "de las reservas",
  },
  option200plusRates2Terms: {
    id: "page.pricing.option200plusRates2Terms",
    description: "pricing 200 plus Rate 2% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200plusRates2Share: {
    id: "page.pricing.option200plusRates2Share",
    description: "pricing 200 plus Rate 2% Share",
    defaultMessage: "Mín. mensual",
  },
  option200plusRates2SharePrice: {
    id: "page.pricing.option200plusRates2SharePrice",
    description: "pricing 200 plus Rate 2% Share price",
    defaultMessage: "49€",
  },
  option200plusRates1Price: {
    id: "page.pricing.option200plusRates1Price",
    description: "pricing 200 plus Rate 1% PerPricecentage",
    defaultMessage: "98€",
  },
  option200plusRates1PriceText: {
    id: "page.pricing.option200plusRates1PriceText",
    description: "pricing 200 plus Rate 1% Price text",
    defaultMessage: "al mes",
  },
  option200plusRates1Percentage: {
    id: "page.pricing.option200plusRates1Percentage",
    description: "pricing 200 plus Rate 1% Percentage",
    defaultMessage: "1%",
  },
  option200plusRates1PercentageText: {
    id: "page.pricing.option200plusRates1PercentageText",
    description: "pricing 200 plus Rate 1% Percentage text",
    defaultMessage: "de las reservas",
  },
  option200plusRates1Terms: {
    id: "page.pricing.option200plusRates1Terms",
    description: "pricing 200 plus Rate 1% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200plusRates05Price: {
    id: "page.pricing.option200plusRates05Price",
    description: "pricing 200 plus Rate 05% PerPricecentage",
    defaultMessage: "162€",
  },
  option200plusRates05PriceText: {
    id: "page.pricing.option200plusRates05PriceText",
    description: "pricing 200 plus Rate 05% Price text",
    defaultMessage: "al mes",
  },
  option200plusRates05Percentage: {
    id: "page.pricing.option200plusRates05Percentage",
    description: "pricing 200 plus Rate 05% Percentage",
    defaultMessage: "0,5%",
  },
  option200plusRates05PercentageText: {
    id: "page.pricing.option200plusRates05PercentageText",
    description: "pricing 200 plus Rate 05% Percentage text",
    defaultMessage: "de las reservas",
  },
  option200plusRates05Terms: {
    id: "page.pricing.option200plusRates05Terms",
    description: "pricing 200 plus Rate 05% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200plusRates0Price: {
    id: "page.pricing.option200plusRates0Price",
    description: "pricing 200 plus Rate 0% PerPricecentage",
    defaultMessage: "308€",
  },
  option200plusRates0PriceText: {
    id: "page.pricing.option200plusRates0PriceText",
    description: "pricing 200 plus Rate 0% Price text",
    defaultMessage: "al mes",
  },
  option200plusRates0Terms: {
    id: "page.pricing.option200plusRates0Terms",
    description: "pricing 200 plus Rate 0% Terms",
    defaultMessage: "Sin permanencia ni licencia",
  },
  option200plusRates0Share: {
    id: "page.pricing.option200plusRates0Share",
    description: "pricing 200 plus Rate 0% Share",
    defaultMessage: "Hasta 500.000€ anuales",
  },
  error: {
    id: "page.pricing.error",
    description: "pricing error message",
    defaultMessage: "No hay precios disponibles con las opciones seleccionadas",
  },
  includeTitle: {
    id: "page.pricing.includeTitle",
    description: "pricing include title",
    defaultMessage: "Incluye",
  },
  includeOne: {
    id: "page.pricing.includeOne",
    description: "pricing include one",
    defaultMessage: "Soporte ilimitado online y telefónico",
  },
  includeTwo: {
    id: "page.pricing.includeTwo",
    description: "pricing include two",
    defaultMessage: "Informes y analítica avanzada",
  },
  includeThree: {
    id: "page.pricing.includeThree",
    description: "pricing include three",
    defaultMessage: "Reglas de precios dinámicos",
  },
  includeFour: {
    id: "page.pricing.includeFour",
    description: "pricing include four",
    defaultMessage:
      "Integración con tu channel manager y descarga de datos de tarjetas de crédito",
  },
  includeFive: {
    id: "page.pricing.includeFive",
    description: "pricing include five",
    defaultMessage: "Métodos de pago alternativos",
  },
  includeSix: {
    id: "page.pricing.includeSix",
    description: "pricing include six",
    defaultMessage: "Central de reservas (multi-propiedades)",
  },
  includeSeven: {
    id: "page.pricing.includeSeven",
    description: "pricing include seven",
    defaultMessage: "Conectividad con metabuscadores",
  },
  includeEight: {
    id: "page.pricing.includeEight",
    description: "pricing include eight",
    defaultMessage: "Cumple normativas de pago PCI-DSS y PSD2",
  },
  includeNine: {
    id: "page.pricing.includeNine",
    description: "pricing include nine",
    defaultMessage: "Generador de tarifas combinadas",
  },
  setUpFee: {
    id: "page.pricing.setUpFee",
    description: "Set-up fee text",
    defaultMessage: "Cuota de alta",
  },
  setUpFeePrice: {
    id: "page.pricing.setUpFeePrice",
    description: "Set-up fee price",
    defaultMessage: "99",
  },
});

export default function Pricing() {
  const intl = useIntl();

  return (
    <>
      <BreadCrumbs
        breadcrumbText={intl.formatMessage(messages.breadcrumb)}
        pageTitle={intl.formatMessage(messages.title)}
        pageIntro={intl.formatMessage(messages.intro)}
      />

      <section className="prices-module">
        <div className="big-wrapper">
          <div className="wrap-prices-module">
            <div className="prices-module-filter">
              <div className="wrap-prices-filter">
                <div className="prices-filter-header">
                  <p className="prices-filter-title">
                    <FormattedMessage {...messages.formTitle} />
                  </p>
                  <p className="prices-filter-subtitle">
                    <FormattedMessage {...messages.formSubtitle} />
                  </p>
                </div>
                <div className="prices-filter-form">
                  <div className="prices-filter-field">
                    <select className="filter-field-select">
                      <option value="1" defaultValue>
                        {intl.formatMessage(messages.form20)}
                      </option>
                      <option value="2">
                        {intl.formatMessage(messages.form50)}
                      </option>
                      <option value="3">
                        {intl.formatMessage(messages.form100)}
                      </option>
                      <option value="4">
                        {intl.formatMessage(messages.form200)}
                      </option>
                      <option value="5">
                        {intl.formatMessage(messages.form200plus)}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <p className="prices-module-title">
                <FormattedMessage {...messages.ratesTitle} />
              </p>
            </div>
            <div className="prices-module-list">
              <div className="wrap-prices-list">
                <div className="prices-list-box" data-filter="1">
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates2} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates2Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option20Rates2Percentage}
                            />
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option20Rates2PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option20Rates2Terms} />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option20Rates2Share} />{" "}
                          <span className="prices-foot-rate_text">
                            <FormattedMessage
                              {...messages.option20Rates2SharePrice}
                            />
                            <small> €</small>
                          </span>
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates1} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates1Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option20Rates1Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option20Rates1PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option20Rates1Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option20Rates1PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option20Rates1Terms} />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates05} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates05Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option20Rates05Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option20Rates05PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option20Rates05Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option20Rates05PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option20Rates05Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates0} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates0Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option20Rates0Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option20Rates0PriceText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option20Rates0Terms} />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option20Rates0Share} />
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prices-list-box" data-filter="2">
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates2} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates2Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option50Rates2Percentage}
                            />
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option50Rates2PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option50Rates2Terms} />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option50Rates2Share} />{" "}
                          <span className="prices-foot-rate_text">
                            <FormattedMessage
                              {...messages.option50Rates2SharePrice}
                            />
                            <small>€</small>
                          </span>
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates1} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates1Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option50Rates1Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option50Rates1PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option50Rates1Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option50Rates1PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option50Rates1Terms} />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates05} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates05Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option50Rates05Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option50Rates05PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option50Rates05Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option50Rates05PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option50Rates05Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates0} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates0Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option50Rates0Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option50Rates0PriceText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option50Rates0Terms} />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage {...messages.option50Rates0Share} />
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prices-list-box" data-filter="3">
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates2} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates2Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option100Rates2Percentage}
                            />
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option100Rates2PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option100Rates2Terms}
                          />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option100Rates2Share}
                          />{" "}
                          <span className="prices-foot-rate_text">
                            <FormattedMessage
                              {...messages.option100Rates2SharePrice}
                            />
                            <small>€</small>
                          </span>
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates1} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates1Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option100Rates1Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option100Rates1PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option100Rates1Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option100Rates1PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option100Rates1Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates05} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates05Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option100Rates05Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option100Rates05PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option100Rates05Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option100Rates05PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option100Rates05Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates0} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates0Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option100Rates0Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option100Rates0PriceText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option100Rates0Terms}
                          />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option100Rates0Share}
                          />
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prices-list-box" data-filter="4">
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates2} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates2Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200Rates2Percentage}
                            />
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200Rates2PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200Rates2Terms}
                          />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200Rates2Share}
                          />{" "}
                          <span className="prices-foot-rate_text">
                            <FormattedMessage
                              {...messages.option200Rates2SharePrice}
                            />
                            <small>€</small>
                          </span>
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates1} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates1Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200Rates1Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200Rates1PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option200Rates1Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200Rates1PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200Rates1Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates05} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates05Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200Rates05Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200Rates05PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option200Rates05Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200Rates05PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200Rates05Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates0} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates0Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200Rates0Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200Rates0PriceText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200Rates0Terms}
                          />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200Rates0Share}
                          />
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prices-list-box" data-filter="5">
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates2} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates2Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200plusRates2Percentage}
                            />
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200plusRates2PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200plusRates2Terms}
                          />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200plusRates2Share}
                          />{" "}
                          <span className="prices-foot-rate_text">
                            <FormattedMessage
                              {...messages.option200plusRates2SharePrice}
                            />
                            <small>€</small>
                          </span>
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates1} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates1Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200plusRates1Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200plusRates1PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option200plusRates1Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200plusRates1PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200plusRates1Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates05} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates05Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200plusRates05Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200plusRates05PriceText}
                            />
                          </span>
                        </div>
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <small>
                              +
                              <FormattedMessage
                                {...messages.option200plusRates05Percentage}
                              />
                            </small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200plusRates05PercentageText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200plusRates05Terms}
                          />
                        </p>
                        <p className="prices-foot-text"></p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prices-list-element">
                    <div className="wrap-prices-element">
                      <div className="prices-element-header">
                        <p className="prices-element-title">
                          <FormattedMessage {...messages.rates0} />
                        </p>
                        <p className="prices-element-subtitle">
                          <FormattedMessage {...messages.rates0Text} />
                        </p>
                      </div>
                      <div className="prices-element-info">
                        <div className="prices-info-element">
                          <span className="prices-info-number">
                            <FormattedMessage
                              {...messages.option200plusRates0Price}
                            />
                            <small>€</small>
                          </span>
                          <span className="prices-info-text">
                            <FormattedMessage
                              {...messages.option200plusRates0PriceText}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="prices-element-foot">
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200plusRates0Terms}
                          />
                          ,
                        </p>
                        <p className="prices-foot-text">
                          <FormattedMessage
                            {...messages.option200plusRates0Share}
                          />
                        </p>
                        <div className="prices-foot-rate">
                          <p className="prices-foot-rate_text">
                            <FormattedMessage {...messages.setUpFee} />{" "}
                            <FormattedMessage {...messages.setUpFeePrice} />€
                          </p>
                        </div>
                        <div className="prices-foot-btn">
                          <div className="btn-corp">
                            <a href={"/contacto"} className="btn-corp-text">
                              <FormattedMessage {...messages.ratesBtn} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="empty-message">
                  <div className="wrap-empty-message">
                    <p className="empty-message-text">
                      <FormattedMessage {...messages.error} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="prices-module-extra">
              <div className="wrap-prices-extra">
                <p className="prices-extra-title">
                  <FormattedMessage {...messages.includeTitle} />
                </p>
                <ul className="prices-extra-list">
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeOne} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeTwo} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeThree} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeFour} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeFive} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeSix} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeSeven} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeEight} />
                      </p>
                    </div>
                  </li>
                  <li className="prices-extra-element">
                    <div className="wrap-extra-element">
                      <p className="extra-element-text">
                        <FormattedMessage {...messages.includeNine} />
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
