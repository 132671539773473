import React, { useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  address: {
    id: "page.footer.address",
    description: "footer address title",
    defaultMessage: "Dirección",
  },
  addressDataOne: {
    id: "page.footer.addressDataOne",
    description: "footer address data",
    defaultMessage: "Manuel Girona 16, Bajos 08034 Barcelona",
  },
  addressDataTwo: {
    id: "page.footer.addressDataTwo",
    description: "footer address data",
    defaultMessage: "Barcelona 08034",
  },
  phone: {
    id: "page.footer.phone",
    description: "footer phone title",
    defaultMessage: "Teléfono",
  },
  phoneNumber: {
    id: "page.footer.phoneNumber",
    description: "footer phone number",
    defaultMessage: "+34 650 57 84 13",
  },
  phoneNumberUrl: {
    id: "page.footer.phoneNumberUrl",
    description: "footer phone number Url",
    defaultMessage: "https://wa.me/650578413",
  },
  email: {
    id: "page.footer.email",
    description: "footer email title",
    defaultMessage: "E-mail",
  },
  emailAddress: {
    id: "page.footer.emailAddress",
    description: "footer email address",
    defaultMessage: "info@flowyhotels.com",
  },
  emailExtra: {
    id: "page.footer.emailExtra",
    description: "footer email extra title",
    defaultMessage: "Delegación Baleares",
  },
  emailExtraAddress: {
    id: "page.footer.emailExtraAddress",
    description: "footer email extra address",
    defaultMessage: "baleares@witbooking.com",
  },
  rrss: {
    id: "page.footer.rrss",
    description: "footer rrss title",
    defaultMessage: "RRSS",
  },
  rrssInstagram: {
    id: "page.footer.rrssInstagram",
    description: "footer rrss Instagram",
    defaultMessage: "Instagram",
  },
  rrssFacebook: {
    id: "page.footer.rrssFacebook",
    description: "footer rrss Facebook",
    defaultMessage: "Facebook",
  },
  webmap: {
    id: "page.footer.webmap",
    description: "footer webmap title",
    defaultMessage: "Mapa web",
  },
  webmapDestinations: {
    id: "page.footer.webmapDestinations",
    description: "footer webmap destinations",
    defaultMessage: "Destinos",
  },
  webmapContact: {
    id: "page.footer.webmapContact",
    description: "footer webmap contact",
    defaultMessage: "Contacto",
  },
  webmapWeeklyDeals: {
    id: "page.footer.webmapWeeklyDeals",
    description: "footer webmap deals",
    defaultMessage: "Deals semanales",
  },
  legal: {
    id: "page.footer.legal",
    description: "footer legal title",
    defaultMessage: "Legal",
  },
  legalAdvice: {
    id: "page.footer.legalAdvice",
    description: "footer legal advice",
    defaultMessage: "Aviso Legal",
  },
  legalPrivacity: {
    id: "page.footer.legalPrivacity",
    description: "footer legal privacity",
    defaultMessage: "Política de privacidad",
  },
  legalCookies: {
    id: "page.footer.legalCookies",
    description: "footer legal cookies",
    defaultMessage: "Política de cookies",
  },
  copy: {
    id: "page.footer.copy",
    description: "footer copy",
    defaultMessage: "Witbookking © 2021",
  },
  rights: {
    id: "page.footer.rights",
    description: "footer rights",
    defaultMessage: "All rights reserved",
  },
});

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="big-wrapper">
        <div className="wrap-main-footer">
          <div
            className="main-footer-top js-scrollto"
            data-scroll_to="body"
            data-scroll_spacer="0"
            role="button"
            tabIndex="0"
          >
            <span className="footer-top-tag">Top</span>
            <i className="footer-top-icon icon-pointer-up"></i>
          </div>
          <div className="main-footer-info">
            <div className="wrap-footer-info">
              <div className="footer-info-box">
                <div className="wrap-info-box">
                  <p className="info-box-title">
                    <FormattedMessage {...messages.address} />
                  </p>
                  <p className="info-box-text">
                    <FormattedMessage {...messages.addressDataOne} />
                  </p>
                  <p className="info-box-text">
                    <FormattedMessage {...messages.addressDataTwo} />
                  </p>
                </div>
              </div>
              <div className="footer-info-box">
                <div className="wrap-info-box">
                  <p className="info-box-title">
                    <FormattedMessage {...messages.phone} />
                  </p>
                  <p className="info-box-text">
                    <FormattedMessage {...messages.phoneNumberUrl}>
                      {(phoneNumberUrl) => (
                        <>
                          <a href={phoneNumberUrl} target="_blank">
                            <i class="subtns-element-icon icon-whatsapp"></i>
                            <FormattedMessage {...messages.phoneNumber} />
                          </a>
                        </>
                      )}
                    </FormattedMessage>
                  </p>
                </div>
                <div className="wrap-info-box">
                  <p className="info-box-title">
                    <FormattedMessage {...messages.email} />
                  </p>
                  <a
                    href="mailto:info@flowyhotels.com"
                    className="info-box-text"
                  >
                    <FormattedMessage {...messages.emailAddress} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main-footer-links">
            <div className="wrap-footer-links">
              <div className="footer-links-box">
                <div className="wrap-links-box">
                  <p className="links-box-title">
                    <FormattedMessage {...messages.rrss} />
                  </p>
                  <a
                    href="https://www.instagram.com/flowyhotels/"
                    target="_blank"
                    rel="noreferrer"
                    className="links-box-text"
                  >
                    <FormattedMessage {...messages.rrssInstagram} />
                  </a>
                  <a
                    href="https://www.facebook.com/flowyhotels"
                    target="_blank"
                    rel="noreferrer"
                    className="links-box-text"
                  >
                    <FormattedMessage {...messages.rrssFacebook} />
                  </a>
                </div>
              </div>
              <div className="footer-links-box">
                <div className="wrap-links-box">
                  <p className="links-box-title">
                    <FormattedMessage {...messages.webmap} />
                  </p>
                  <a
                    href={
                      "https://engine.witbooking.com/flowyhotels.com/availability?origin=flowy"
                    }
                    target="_blank"
                    className="links-box-text"
                  >
                    <FormattedMessage {...messages.webmapDestinations} />
                  </a>
                  <a href={"/contacto"} className="links-box-text">
                    <FormattedMessage {...messages.webmapContact} />
                  </a>
                  <a href="/#deals" className="links-box-text">
                    <FormattedMessage {...messages.webmapWeeklyDeals} />
                  </a>
                </div>
              </div>
              <div className="footer-links-box">
                <div className="wrap-links-box">
                  <p className="links-box-title">
                    <FormattedMessage {...messages.legal} />
                  </p>
                  <a href="/legal" className="links-box-text">
                    <FormattedMessage {...messages.legalAdvice} />
                  </a>
                  <a href="/policy" className="links-box-text">
                    <FormattedMessage {...messages.legalPrivacity} />
                  </a>
                  <a href="/cookies" className="links-box-text">
                    <FormattedMessage {...messages.legalCookies} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="main-footer-sign">
            <div className="wrap-footer-sign">
              <p className="footer-sign-text">
                <FormattedMessage {...messages.copy} />
              </p>
              <p className="footer-sign-text">
                <FormattedMessage {...messages.rights} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
