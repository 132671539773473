import React, { useCallback, useContext, useState } from "react";

//we are setting the default language to "ES" because there is no English website yet

const LanguageContext = React.createContext({
  currentLocale: "es",
});

export function LocaleProvider(props) {
  return (
    <LanguageContext.Provider>{props.children("es")}</LanguageContext.Provider>
  );
}
