import React from "react";

// Assets
import "../styles/imgTextBanner.scss";

// Components
import ImageComponent from "./imageComponent";
import TextComponent from "./textComponent";

export default function ImgTextBanner({
  imgSrc,
  titleText,
  subtitleText,
  paragraphText,
  buttonText,
  buttonText2,
  imagePosition,
  buttonInfo,
}) {
  {
    if (imagePosition) {
      return (
        <div className="imgTextContainer">
          <div className="imageComponent">
            <ImageComponent imgSrc={imgSrc} />
          </div>
          <div className="textComponent">
            <TextComponent
              titleText={titleText}
              subtitleText={subtitleText}
              paragraphText={paragraphText}
              buttonText={buttonText}
              buttonText2={buttonText2}
              buttonInfo={buttonInfo}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="imgTextContainer">
          <div className="textComponent">
            <TextComponent
              titleText={titleText}
              subtitleText={subtitleText}
              paragraphText={paragraphText}
              buttonText={buttonText}
              buttonInfo={buttonInfo}
            />
          </div>
          <div className="imageComponent">
            <ImageComponent imgSrc={imgSrc} mobileHide={true} />
          </div>
        </div>
      );
    }
  }
}
