import React, { useCallback, useMemo, useState } from "react";

// Components
import MailchimpSubscribe from "react-mailchimp-subscribe";

// Styles
import "../../styles/newsletter.scss";

const { REACT_APP_MAILCHIMP_URL } = process.env;

function MailchimpForm({ status, message, isMobile, onValidated }) {
  const [emailValue, setEmailValue] = useState("");
  const submit = useCallback(
    (event) => {
      event.preventDefault();
      onValidated({
        EMAIL: emailValue,
      });
    },
    [emailValue, onValidated]
  );
  return (
    <form onSubmit={submit}>
      <div className="formContainer">
        {status && (
          <div>
            {status === "sending" && (
              <div className="sendingMessage">Envío en curso...</div>
            )}
            {status === "error" && (
              <div className="errorMessage">
                <div className="titleMessage">Error</div>
                <span dangerouslySetInnerHTML={{ __html: message }} />
              </div>
            )}
            {status === "success" && (
              <div className="successMessage">
                <div className="titleMessage">¡Enviado con éxito!</div>
              </div>
            )}
          </div>
        )}
        {!status && (
          <>
            <div>
              <input
                onChange={(e) => setEmailValue(e.target.value)}
                value={emailValue}
                required
                type="email"
                placeholder="Correo electrónico"
                className="mailchimp-input"
              />
            </div>
            <div>
              <button className="mailchimp-submit" type="submit">
                {isMobile ? <button /> : "Suscribirse"}
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

const Newsletter = ({ width }) => {
  const isMobile = useMemo(
    () => width === "xs" || width === "sm" || width === "md",
    [width]
  );

  return (
    <div>
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <MailchimpForm
            isMobile={isMobile}
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default Newsletter;
