import React, { useCallback, useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import backgroundImage1 from "../assets/img/hotel_background.png";
import backgroundImage2 from "../assets/img/hotel_background2.jpg";
import backgroundImage3 from "../assets/img/hotel_background3.jpg";
import Newsletter from "../common/components/Newsletter";

export default function InlinksBanner({ bannerClass }) {
  const messages = defineMessages({
    title: {
      id: "page.banner.title",
      description: "banner title",
      defaultMessage: "Síguenos en Instagram",
    },
    subtitle: {
      id: "page.banner.subtitle",
      description: "banner subtitle",
      defaultMessage: "LLÉNATE DE INSPIRACIÓN",
    },
    text: {
      id: "page.banner.text",
      description: "banner text",
      defaultMessage:
        "Visita nuestra cuenta de Instagram y descubre las mejores ideas para tu próxima escapada.",
    },
    btnOne: {
      id: "page.banner.btnOne",
      description: "button text",
      defaultMessage: "Síguenos",
    },
    title2: {
      id: "page.banner.title2",
      description: "banner title",
      defaultMessage: "Suscríbete a nuestros deals semanales",
    },
    text2: {
      id: "page.banner.text2",
      description: "banner text",
      defaultMessage:
        "Suscríbete a nuestra newsletter semanal y recibirás propuestas exclusivas de nuestros hoteles miembros a los mejores precios.",
    },
    title3: {
      id: "page.banner.title3",
      description: "banner title",
      defaultMessage: "¿Quieres saber más sobre Flowy?",
    },
    text3: {
      id: "page.banner.text3",
      description: "banner text",
      defaultMessage:
        "Ponte en contacto con nosotros para conocer las ventajas de formar \n parte de la familia Flowy.",
    },
    btnTwo: {
      id: "page.banner.contact",
      description: "button text",
      defaultMessage: "Contacto",
    },
  });

  {
    if (bannerClass === "Instagram") {
      return (
        <div className={`inlinks-banner`}>
          <div className="inlinks-banner-div-white"></div>
          <div className="big-wrapper center-banner">
            <div className="wrap-inlinks-banner">
              <p className="inlinks-banner-title">
                <FormattedMessage {...messages.title} />
              </p>
              <p className="inlinks-banner-subtitle">
                <FormattedMessage {...messages.subtitle} />
              </p>
              <p className="inlinks-banner-text display-linebreak">
                <FormattedMessage {...messages.text} />
              </p>
              <div className="inlinks-banner-btns">
                <div className="banner-btns-element btn-corp">
                  <a
                    className="btn-corp-text"
                    href="https://www.instagram.com/flowyhotels/"
                  >
                    <FormattedMessage {...messages.btnOne} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (bannerClass === "Subscribe") {
      return (
        <div className={`inlinks-banner`} id="deals">
          <div className="inlinks-banner-div-white"></div>
          <div className="big-wrapper center-banner">
            <div className="wrap-inlinks-banner">
              <p className="inlinks-banner-title">
                <FormattedMessage {...messages.title2} />
              </p>
              <p className="inlinks-banner-text banner-text-mobile">
                <FormattedMessage {...messages.text2} />
              </p>
              <Newsletter />
            </div>
          </div>
        </div>
      );
    }
    if (bannerClass === "Contact") {
      return (
        <div className={`inlinks-banner`}>
          <div className="inlinks-banner-div-color"></div>
          <div className="big-wrapper center-banner">
            <div className="wrap-inlinks-banner">
              <p className="inlinks-banner-title-white">
                <FormattedMessage {...messages.title3} />
              </p>
              <p className="inlinks-banner-text-white">
                <FormattedMessage {...messages.text3} />
              </p>
              <div className="inlinks-banner-btns">
                <div className="banner-btns-element btn-corp2">
                  <a className="btn-corp-text" href="/contacto">
                    <FormattedMessage {...messages.btnTwo} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
