import React from "react";

// Assets
import "../styles/taggedImg.scss";

// Components

export default function TaggedImg({ Info }) {
  const TaggedInfo = Info;

  return (
    <div>
      <div>
        {TaggedInfo &&
          TaggedInfo.map((elem) => (
            //defino aqui backgroundImage para no tener que hacer distintos componentes para cada imagen (las imagenes son distintas)
            <div
              className="container"
              style={{ backgroundImage: `url(${elem.image})` }}
            >
              {elem.paragraph4 ? (
                <div>
                  <p className="paragraph position1-p4 line-white">
                    {elem.paragraph1}
                  </p>
                  <p className="paragraph position2-p4 line-color">
                    {elem.paragraph2}
                  </p>
                  <p className="paragraph position3-p4 line-white">
                    {elem.paragraph3}
                  </p>
                  <p className="paragraph position4-p4 line-white">
                    {elem.paragraph4}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="paragraph position1 line-white">
                    {elem.paragraph1}
                  </p>
                  <p className="paragraph position2 line-color">
                    {elem.paragraph2}
                  </p>
                  <p className="paragraph position3 line-white">
                    {elem.paragraph3}
                  </p>
                </div>
              )}
              <div className="hotelDescription">
                {<p className="hotelName">{elem.hotelName}</p>}
                {<p className="hotelLocation">{elem.hotelLocation}</p>}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
