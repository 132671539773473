import React from "react";

// Components
import Nav from "./nav";
import Home from "../pages/home";
import Footer from "./footer";
import Contact from "../pages/contact";
import Members from "../pages/members";
import Legal from "../pages/legal";
import Policy from "../pages/policy";
import Cookies from "../pages/cookies";

export default function Layout({
  isContact,
  isMembers,
  isLegal,
  isPolicy,
  isCookies,
}) {
  return (
    <>
      <Nav />

      {isContact ? (
        <div>
          <div className="spacer"></div>
          <Contact />
        </div>
      ) : isMembers ? (
        <div>
          <div className="spacer"></div>
          <Members />
        </div>
      ) : isLegal ? (
        <div>
          <div className="spacer"></div>
          <Legal />
        </div>
      ) : isPolicy ? (
        <div>
          <div className="spacer"></div>
          <Policy />
        </div>
      ) : isCookies ? (
        <div>
          <div className="spacer"></div>
          <Cookies />
        </div>
      ) : (
        <Home />
      )}

      <Footer />
    </>
  );
}
