import React from "react";

// Assets
import "../styles/imageComponent.scss";

// Components

export default function ImgComponent({ imgSrc, mobileHide }) {
  if (mobileHide) {
    return (
      <div>
        <img className="imgComponentImg hide" src={imgSrc} />
      </div>
    );
  } else {
    return (
      <div>
        <img className="imgComponentImg" src={imgSrc} />
      </div>
    );
  }
}
