import React, { useCallback, useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

// Assets
import Logo from "../assets/img/flowy_logo.png";

const messages = defineMessages({
  home: {
    id: "page.nav.home",
    description: "Nav option home",
    defaultMessage: "Home",
  },
  weeklyDeals: {
    id: "page.nav.weeklyDeals",
    description: "Nav option weekly deals",
    defaultMessage: "Deals semanales",
  },
  menu: {
    id: "page.nav.menu",
    description: "Nav option menu",
    defaultMessage: "Menú",
  },
  hotels: {
    id: "page.nav.hotels",
    description: "Nav option hotels",
    defaultMessage: "Destinos",
  },
  login: {
    id: "page.nav.login",
    description: "Nav option login",
    defaultMessage: "Login",
  },
  contact: {
    id: "page.nav.contact",
    description: "Nav option contact",
    defaultMessage: "Contacto",
  },
  selectALanguage: {
    id: "page.nav.selectALanguage",
    description: "Nav option select a language",
    defaultMessage: "Selecciona un idioma",
  },
});

export default function Nav() {
  return (
    <>
      <nav className="main-menu">
        <div className="wrap-main-menu">
          <div className="main-menu-logo">
            <a href="/">
              <img
                src={Logo}
                className="menu-logo-element"
                alt="logo Witbooking"
                loading="lazy"
              />
            </a>
          </div>
          <div className="main-menu-links">
            <div className="wrap-menu-links">
              <div className="menu-links-list">
                <div className="menu-links-element dropdown">
                  <a
                    href="/"
                    className="wrap-links-element"
                    role="button"
                    tabIndex="0"
                  >
                    <p className="links-element-text">
                      <FormattedMessage {...messages.home} />
                    </p>
                  </a>
                </div>
                <div className="menu-links-element dropdown">
                  <a
                    href={
                      "https://engine.witbooking.com/flowyhotels.com/availability?origin=flowy"
                    }
                    target="_blank"
                    className="wrap-links-element"
                    role="button"
                    tabIndex="0"
                  >
                    <p className="links-element-text">
                      <FormattedMessage {...messages.hotels} />
                    </p>
                  </a>
                </div>

                <div className="menu-links-element dropdown">
                  <a
                    href={"/contacto"}
                    className="wrap-links-element"
                    role="button"
                    tabIndex="0"
                  >
                    <p className="links-element-text">
                      <FormattedMessage {...messages.contact} />
                    </p>
                  </a>
                </div>
                <div className="menu-sublinks-list">
                  <a className="menu-sublinks-element" href="/#deals">
                    <div className="wrap-sublinks-element">
                      <p className="sublinks-element-text">
                        <FormattedMessage {...messages.weeklyDeals} />
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main-menu-btn js-toggle-menu"
            role="button"
            tabIndex="0"
          >
            <div className="menu-btn-box">
              <i className="menu-btn-icon icon-menu"></i>
            </div>
            <div className="menu-btn-box toclose">
              <i className="menu-btn-icon icon-close"></i>
            </div>
          </div>
          <div className="main-menu-subtns">
            <a
              href="https://www.instagram.com/flowyhotels/"
              without
              rel="noreferrer"
              target="_blank"
              className="menu-subtns-element"
            >
              <div className="wrap-subtns-element">
                <i className="subtns-element-icon icon-instagram "></i>
              </div>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
