import React from "react";
import "./App.css";
import "./styles/main.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import messagesEs from "./translations/es.json";
import { LocaleProvider } from "./context/localeContext";

// Components
import Home from "./pages/home";
import Nav from "./layout/nav";
import Footer from "./layout/footer";
import Layout from "./layout/layout";
import Contact from "./pages/contact";
import Pricing from "./pages/pricing";
import Members from "./pages/members";
import Legal from "./pages/legal";
import Policy from "./pages/policy";
import Cookies from "./pages/cookies";

function App() {
  const messages = {
    "es-ES": messagesEs,
  };
  return (
    <Router>
      <LocaleProvider>
        {(locale) => (
          <IntlProvider
            locale={locale}
            messages={messages["es-ES"]}
            defaultLocale="es-ES"
          >
            <Switch>
              <Route exact path="/">
                <Layout />
              </Route>
              <Route
                exact
                path="/contacto"
                render={(props) => <Layout {...props} isContact={true} />}
              />
              <Route
                exact
                path="/miembros"
                render={(props) => <Layout {...props} isMembers={true} />}
              />
              <Route
                exact
                path="/legal"
                render={(props) => <Layout {...props} isLegal={true} />}
              />
              <Route
                exact
                path="/policy"
                render={(props) => <Layout {...props} isPolicy={true} />}
              />
              <Route
                exact
                path="/cookies"
                render={(props) => <Layout {...props} isCookies={true} />}
              />
            </Switch>
          </IntlProvider>
        )}
      </LocaleProvider>
    </Router>
  );
}

export default App;
